import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { motion } from "framer-motion"

const TagPage = ({ data: { allFile: { nodes } }, pageContext: {tag}}) => {
    return (<Layout pageTitle={`tag: ${tag}`} description={`All blog posts tagged with ${tag}`}>
        <motion.h1 layoutId={`tag${tag}`}>{tag}</motion.h1>
        <ul>
            {nodes.map(({ name, childMdx: { frontmatter: { title, date }, id } }) => {
                return (<motion.li key={name} layoutId={`${id}title`}><Link to={`/blog/${name}`}>
                    {title}
                </Link></motion.li>)
            })}
        </ul>
    </Layout>)
}

export default TagPage

export const query = graphql`
query MyQuery($tag: String) {
  allFile(
    filter: {childMdx: {body: {ne: null}, frontmatter: {draft: {ne: true}, tags: {eq: $tag}}}}
  ) {
    nodes {
      name
      childMdx {
        id
        frontmatter {
          date
          title
        }
      }
    }
  }
}
`
